<template>
  <div id="claimInfo">
    <div>
      <demo-block title="" >
        <van-sticky>
          <van-cell v-if="3 === claimInfo.claimStatus
          || 5 === claimInfo.claimStatus
          || 8 === claimInfo.claimStatus
          || 16 === claimInfo.claimStatus
          || 17 === claimInfo.claimStatus
          || 19 === claimInfo.claimStatus
          || 21 === claimInfo.claimStatus
          || 27 === claimInfo.claimStatus
          || 28 === claimInfo.claimStatus"
                    style="background-color: #F56C6C; color: #F2F6FC;"
                    value-class="hello"
                    center
                    :value="claimInfo.remark" icon="info-o">
            <template #right-icon>
            </template>
          </van-cell>
          <van-cell v-else-if="9 === claimInfo.claimStatus" style="background-color: #67C23A; color: #F2F6FC;" value-class="hello"  center :value="claimInfo.remark" icon="info-o">
            <template #right-icon>
            </template>
          </van-cell>
          <van-cell v-else style="background-color: #409EFF; color: #F2F6FC;" value-class="hello"  center :value="claimInfo.remark" icon="info-o">
            <template #right-icon>
            </template>
          </van-cell>
        </van-sticky>
      </demo-block>
    </div>

    <van-form>
      <!--报案信息-->
      <demo-block title="报案信息">
        <van-field
            v-model="claimInfo.driveReportVo.reportName"
            name="insuredCardNo"
            label="报案人姓名"
            readonly
        />
        <van-field
            v-model="claimInfo.driveReportVo.reportPhoneNo"
            name="insuredName"
            label="报案人手机号"
            type="tel"
            readonly
        />
        <!-- 理赔类型（1-单科不过， 2-重学学车） -->
        <van-field
            readonly
            name="claimDriveType"
            :value="claimDriveTypeMap[claimInfo.driveReportVo.claimDriveType]"
            label="理赔类型"
        />

        <!--考试科目（1-科目一， 2-科目二， 3-科目三，4-科目四）-->
        <van-field
            readonly
            clickable
            name="picker"
            v-if="claimInfo.driveReportVo.claimDriveType===1"
            :value="claimSubjectMap[claimInfo.driveReportVo.subject]"
            label="考试科目"
            placeholder="请选择"
            :rules="[{ required: true, message: '请选择考试科目'}]"
            @click="claimSubjectShowPicker = true"
        />
        <!--考试省市地址-->
        <van-field
            readonly
            clickable
            name="picker"
            :value="examProvinceAndCityValue"
            label="考试地址"
            placeholder="请选择"
        />
        <!--考试详细地址-->
        <van-field
            readonly
            v-model="claimInfo.driveReportVo.examAddress"
            name="insuredName"
            label="详细地址"
        />
        <!--考试时间-->
        <van-field
            readonly
            clickable
            name="examTime"
            :value="claimInfo.driveReportVo.examTime"
            label="考试时间"
        />
        <!--考试费用-->
        <van-field
            readonly
            v-model="claimInfo.driveReportVo.resit"
            type="number"
            label="考试费用"
        />
        <!--122登录密码-->
        <van-field
            readonly
            v-model="claimInfo.driveReportVo.govCnPswd"
            name="insuredName"
            label="122登录密码"
        />
      </demo-block>

      <demo-block title="银行卡信息">
        <!--理赔账号类型-->
        <van-field
            readonly
            clickable
            name="picker"
            :value="claimInfo.claimTypeName"
            label="账号类型"
        />
        <!-- 持卡人姓名 -->
        <van-field
            readonly
            v-model="claimInfo.bankAccountVo.accountName"
            name="insuredCardNo"
            label="持卡人姓名"
        />

        <!-- 持卡人证件号 -->
        <van-field
            readonly
            v-model="claimInfo.bankAccountVo.accountCardNo"
            name="insuredCardNo"
            label="持卡人证件号"
            clearable
        />
        <!-- 银行卡号 -->
        <van-field
            readonly
            v-model="claimInfo.bankAccountVo.accountNo"
            name="insuredCardNo"
            label="银行卡号"
        />
        <!-- 开户银行 -->
        <van-field
            readonly
            clickable
            name="picker"
            :value="claimInfo.bankAccountVo.bankName"
            label="开户银行"
        />

        <!-- 开户地区 -->
        <van-field
            readonly
            clickable
            :value="bankProvinceAndCityValue"
            label="开户地址"
        />

        <!-- 开户支行 -->
        <van-field
            readonly
            clickable
            name="subBankName"
            :value="claimInfo.bankAccountVo.subBankName"
            label="开户支行"
            autosize
        />
      </demo-block>

      <demo-block title="理赔材料">
        <div  v-for="materialType in materialTypeList" :key="materialType.id">
          <van-cell><span v-html="materialType.remark"></span></van-cell>
          <van-field name="uploader" label="">
            <template #input>
              <van-uploader :show-upload="false" :deletable="false"  v-model="materialType.fileList" :max-count="materialType.maxCount"/>
            </template>
          </van-field>
        </div>
      </demo-block>
    </van-form>


    <div style="margin-bottom: 16px;" v-if="3 === claimInfo.claimStatus
    || 5 === claimInfo.claimStatus
    || 8 === claimInfo.claimStatus
    || 16 === claimInfo.claimStatus
    || 17 === claimInfo.claimStatus
    || 19 === claimInfo.claimStatus
    || 21 === claimInfo.claimStatus
    || 27 === claimInfo.claimStatus
    || 28 === claimInfo.claimStatus">
      <van-tabbar :fixed="true" :placeholder="true">
        <van-button block type="info" @click="fixClaimInfo">
          修改材料
        </van-button>
      </van-tabbar>
    </div>
  </div>

</template>

<script>

import { Notify} from 'vant';
import DemoBlock from "@/components/common/DemoBlock";
import {getAllNeedClaimInfo} from "@/api/claim";

export default {
  name: 'claimInfo',
  components: {DemoBlock},
  data() {
    return {
      loading:false,
      claimProtocolShow:false,
      claimDriveTypeShowPicker: false,
      claimSubjectShowPicker: false,
      examProvinceAndCityShowPicker: false,
      examTimeShowPicker: false,
      claimTypeShowPicker: false,
      bankCodeShowPicker: false,
      bankProvinceAndCityShowPicker: false,
      subBankShowPicker:false,
      examTimeMinDate:new Date(2017, 0, 1),
      examTimeMaxDate:new Date(),
      examTimeCurrentDate:new Date(),
      claimDriveTypeColumns: [
        {text: "单科不过", value: 1},
        {text: "重学", value: 2}
      ],
      claimDriveTypeMap: {
        1:"单科不过",
        2:"重学",
      },
      claimSubjectColumns: [
        {text: "科目一", value: 1},
        {text: "科目二", value: 2},
        {text: "科目三", value: 3},
        {text: "科目四", value: 4}
      ],
      claimSubjectMap: {
        1:"科目一",
        2:"科目二",
        3:"科目三",
        4:"科目四",
      },
      provinceAndCityColumns: [],
      tianAnProvinceAndCityColumns:[],
      tianAnBankColumns:[],
      tianAnSubBankColumns:[],
      tianAnBankInputName:"",
      examProvinceAndCityValue: '',
      bankProvinceAndCityValue: '',
      claimTypeColumns: [
        {text: '本人', value: 1},
        {text: '公司', value: 2},
        {text: '业务人员', value: 3}
      ],
      claimTypeMap: {
        1:"本人",
        2:"公司",
        3:"业务人员"
      },
      isReadClaimProtocol:false,
      policyInfo:{
        policyNo: null,
        insuredCardNo: null,
        insuredName:null,
        safeguardTime:null,
      },
      claimInfo: {
        claimCode: null,
        policyNo: null,
        insuredCardNo: null,
        claimType: null,
        claimTypeName:null,
        claimStatus: null,
        claimStatusName: null,
        remark: null,
        cmpClaimId: null,
        driveReportVo: {
          reportName: null,
          reportPhoneNo: null,
          claimDriveType: null,
          claimDriveTypeName: null,
          subject: null,
          subjectName:null,
          examProvinceCode: null,
          examProvinceName: null,
          examCityCode: null,
          examCityName: null,
          examAddress: null,
          examTime: null,
          resit: null,
          govCnPswd: null
        },
        bankAccountVo: {
          accountName: null,
          accountCardNo: null,
          accountNo: null,
          cardType: null,
          cardTypeName: null,
          bankCode: null,
          bankName: null,
          bankProvinceCode: null,
          bankProvinceName: null,
          bankCityCode: null,
          bankCityName: null,
          subBankName: null,
          subBankCode: null
        },
        materialVoList:[]
      },
      materialTypeList:[],
      materialTypeTempList:[]
    };
  },
  mounted () {
    this.loadData();
  },
  methods: {
    loadData(){
      this.claimInfo.claimCode = this.$route.query.claimCode;

      getAllNeedClaimInfo({
        claimCode:this.claimInfo.claimCode
      })
          .then(res => {
            console.log(res)
            if (200 === res.code) {
              //保单信息
              this.policyInfo = res.data.policyInfo;
              this.policyInfo.safeguardTime = this.policyInfo.beginTime + " ~ " + this.policyInfo.endTime;
              //理赔信息
              if (undefined !== res.data.claimInfo) {
                this.claimInfo = res.data.claimInfo;
                this.claimInfo.claimTypeName = this.claimTypeMap[this.claimInfo.claimType];
                if (this.claimInfo.driveReportVo
                    && this.claimInfo.driveReportVo.examProvinceName
                    && this.claimInfo.driveReportVo.examCityName) {
                  this.examProvinceAndCityValue = this.claimInfo.driveReportVo.examProvinceName + "  " + this.claimInfo.driveReportVo.examCityName;
                }
                if (this.claimInfo.bankAccountVo
                    && this.claimInfo.bankAccountVo.bankProvinceName
                    && this.claimInfo.bankAccountVo.bankCityName) {
                  this.bankProvinceAndCityValue = this.claimInfo.bankAccountVo.bankProvinceName + "  " + this.claimInfo.bankAccountVo.bankCityName;
                }
              }

              //理赔材料信息
              if (undefined !== res.data.materialTypeList) {
                for (var idx in res.data.materialTypeList) {
                  var materialType = res.data.materialTypeList[idx];
                  materialType.maxCount = 0;
                  var fileList = [];
                  if (undefined !== this.claimInfo
                      && undefined !== this.claimInfo.materialVoList
                      && this.claimInfo.materialVoList.length > 0) {
                    materialType.maxCount = this.claimInfo.materialVoList.length;
                    for (var index in this.claimInfo.materialVoList) {
                      var material = this.claimInfo.materialVoList[index];
                      if (materialType.materialType == material.materialType) {
                        fileList.push({
                          url: material.materialUrl,
                          materialType: material.materialType
                        })
                      }
                    }
                  }
                  materialType.fileList = fileList;
                  this.materialTypeList.push(materialType);
                  this.materialTypeTempList.push(materialType);
                }
              }

            } else {
              Notify({ type: 'warning', message: res.message });
            }
            this.loading = false;
          })
          .catch(() => {
            Notify({ type: 'warning', message: '加载失败' });
          });
    },
    fixClaimInfo(){
      this.$router.push({path: '/claim/claimInsert', query: {claimCode: this.claimInfo.claimCode}});
    }

  },
}
</script>

<style>
.hello{
  color: #F2F6FC;
}
</style>
